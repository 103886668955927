import { Button, Col, Row, Space, Input, Radio, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import { showConfirmation } from "../../hooks/showConfirmation";
import Api, { Rotas } from "../../services/api";
import { DeleteOutlined, EditOutlined, SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IOferta } from "../../types/IOferta";
import moment from "moment";
import { SortableTable } from "../../components/SortableTable";
import { DEFAULT_DATE_FORMAT } from "../../components/DatePickerPtBr";
import NotificationModal from "../../components/NotificationModal";
import { useCallback, useState } from "react";
import "./index.css";
import { showToast } from "../../hooks/showToast";
import { NotificationType } from "../../types/INotification";
import { INotificationPayload } from "../../types/INotificationPayload";
import Fuse from "fuse.js";

const { Search } = Input;

export default function Oferta() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [tipo, setTipo] = useState<"ativos" | "inativos" | "futuras">("ativos");
  const { data, isLoading, isError, refetch } = useQuery<IOferta[]>(
    Rotas["/oferta/ativos"],
    {
      enabled: tipo === "ativos",
    }
  );
  const {
    data: dataInativos,
    isLoading: isLoadingInativos,
    isError: isErrorInativos,
    refetch: refetchInativos,
  } = useQuery<IOferta[]>(Rotas["/oferta/inativos"], {
    enabled: tipo === "inativos",
  });
  const {
    data: dataFuturas,
    isLoading: isLoadingFuturas,
    refetch: refetchFuturas,
  } = useQuery<IOferta[]>(Rotas["/oferta/futuras"], {
    enabled: tipo === "futuras",
  });
  const [filteredData, setFilteredData] = useState<IOferta[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const onDelete = useCallback(
    (userId: string) => {
      async function callApiDelete() {
        const url = Rotas["/oferta/:id"].replace(":id", userId);
        await Api.delete(url);
        refetch();
        if (tipo === "inativos") {
          refetchInativos();
        } else if (tipo === "futuras") {
          refetchFuturas();
        }
      }
      showConfirmation({
        onConfirm: callApiDelete,
      });
    },
    [tipo, refetch, refetchInativos, refetchFuturas]
  );

  const onSort = useCallback(
    async (oldIndex: number, newIndex: number) => {
      if (!data) {
        return;
      }
      setLoading(true);
      const oldIndexGlobal = data[oldIndex].index;
      const newIndexGlobal = data[newIndex].index;
      await Api.post(Rotas["/oferta/ordenar"], {
        oldIndex: oldIndexGlobal,
        newIndex: newIndexGlobal,
      });
      refetch();
      setLoading(false);
    },
    [data, refetch]
  );

  const sendNotification = useCallback(async (oferta: IOferta) => {
    const payload = {
      tipo: NotificationType.Oferta,
      mensagem: oferta.nomeProduto,
      referenceId: oferta.id,
    } as INotificationPayload;

    if (oferta.notificarComImagem && oferta?.imagem?.url) {
      payload.imageUrl = oferta.imagem.url;
    }

    await Api.post(Rotas["/notificar"], payload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }, []);

  const sendNotificationText = useCallback(async (message: string) => {
    await Api.post(Rotas["/notificar"], {
      tipo: NotificationType.Oferta,
      mensagem: message,
    } as INotificationPayload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }, []);

  const onSearch = useCallback(
    (search: string) => {
      const dataToFilter = (() => {
        if (tipo === "ativos") {
          return data;
        }
        if (tipo === "inativos") {
          return dataInativos;
        }
        if (tipo === "futuras") {
          return dataFuturas;
        }
      })();

      if (!dataToFilter) {
        return;
      }

      const fuse = new Fuse(dataToFilter, {
        keys: ["nomeProduto", "id"],
      });

      const result = fuse.search(search);
      setFilteredData(result.map((item) => item.item));

      setSearchTerm(search);
    },
    [tipo, data, dataInativos, dataFuturas]
  );

  const columns: ColumnsType<IOferta> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 70,
    },
    {
      title: "Nome",
      dataIndex: "nomeProduto",
      key: "nomeProduto",
      width: "20%",
    },
    {
      title: "Unidade",
      dataIndex: "unidadeMedida",
      key: "unidadeMedida",
      ellipsis: true,
    },

    {
      title: "Original",
      dataIndex: "valorOriginal",
      key: "valorOriginal",
    },
    {
      title: "Promoção",
      dataIndex: "valorPromocao",
      key: "valorPromocao",
    },
    {
      title: "Início",
      dataIndex: "dataInicio",
      key: "dataInicio",
      render: (_, item) => moment(item.dataInicio).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Fim",
      dataIndex: "dataFim",
      key: "dataFim",
      render: (_, item) => moment(item.dataFim).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Ações",
      dataIndex: "options",
      key: "id",
      render: (_, item) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                showConfirmation({
                  onConfirm: () => sendNotification(item),
                });
              }}
              shape="circle"
              icon={<SoundOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => history.push(`/ofertas/new-edit/${item.id}`)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => onDelete(item.id)}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  if (isError || isErrorInativos) {
    return <p>Error</p>;
  }

  return (
    <div>
      <Row justify="space-around" gutter={[24, 24]}>
        <Col span={8}>
          <Button
            onClick={() => history.push("/ofertas/new-edit")}
            type="primary"
            size="large"
          >
            Criar novo
          </Button>
        </Col>
        <Col span={8}>
          <Button
            onClick={() => setModalOpen(true)}
            type="primary"
            size="large"
          >
            Enviar Notificações
          </Button>
        </Col>
        <Col span={24}>
          <Search
            placeholder="pesquise por nome, detalhe ou código"
            onSearch={onSearch}
            enterButton
            value={searchTerm}
            onChange={(ev) => setSearchTerm(ev.target.value)}
          />
        </Col>
        <Col span={24}>
          <Radio.Group
            onChange={(v) => {
              setTipo(
                (() => {
                  if (v.target.value === 1) {
                    return "ativos";
                  }
                  if (v.target.value === 2) {
                    return "inativos";
                  }
                  if (v.target.value === 3) {
                    return "futuras";
                  }
                  return "ativos";
                })()
              );
              setFilteredData([]);
              setSearchTerm("");
            }}
            value={tipo === "ativos" ? 1 : tipo === "inativos" ? 2 : 3}
          >
            <Radio value={1}>Apenas ativos (período em vigor)</Radio>
            <Radio value={2}>Apenas inativos (período expirado)</Radio>
            <Radio value={3}>Futuras</Radio>
          </Radio.Group>
        </Col>

        <Col className="noselect" span={24}>
          {tipo === "ativos" && (
            <SortableTable
              loading={loading || isLoading || isLoadingInativos}
              data={filteredData.length > 0 ? filteredData : data ?? []}
              columns={columns}
              onSortEnd={onSort}
            />
          )}

          {tipo === "inativos" && (
            <Table
              rowKey="id"
              loading={loading || isLoading || isLoadingInativos}
              dataSource={
                filteredData.length > 0 ? filteredData : dataInativos ?? []
              }
              columns={columns}
            />
          )}

          {tipo === "futuras" && (
            <Table
              rowKey="id"
              loading={loading || isLoading || isLoadingFuturas}
              dataSource={
                filteredData.length > 0 ? filteredData : dataFuturas ?? []
              }
              columns={columns}
            />
          )}
        </Col>
      </Row>
      {modalOpen && (
        <NotificationModal
          additionalMessage="Exemplo: Confira já nossas ofertas do dia."
          onCloseModal={() => setModalOpen(false)}
          onOkModal={sendNotificationText}
        />
      )}
    </div>
  );
}
